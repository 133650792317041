<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img v-if="checkShow" style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;" :src="botAvatar" />

                <div v-else style="width: 32px;"></div>
            </div>
            <div class="gpt-yes-no-message">
                <div class="gpt-yes-no-message__content">{{ $t('Was your question resolved?') }}
                    <div class="gpt-yes-no-message__wrap">
                        <div v-if="checkDisable" class="end-chat-btn-disable">
                            <div
                                style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex;">
                                <div class="end-chat-btn-title">{{ $t('Forced termination') }}</div>
                            </div>
                        </div>
                        <div v-else class="end-chat-btn" @click="handleEndChatMsg()">
                            <div
                                style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex; line-height: 1.5;">
                                <div class="end-chat-btn-title">{{ $t('Forced termination') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        checkShow: {
            type: Boolean,
            required: true,
        },
        checkDisable: {
            type: Boolean,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    computed: {},
    methods: {
        handleEndChatMsg() {
            this.$eventBus.publish('click-end-chat-btn')
        },
    },
}
</script>

<style>
.gpt-yes-no-message {
    flex-grow: 1;
}

.gpt-yes-no-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-yes-no-message__wrap {
    display: flex;
    margin-top: 10px;
}

.gpt-yes-no-message__item {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: pointer;
}

.gpt-yes-no-message__item-yes {
    background-color: #009c77;
    color: white;
}

.gpt-yes-no-message__item-no {
    background-color: #e21124;
    color: white;
}

.end-chat-btn {
    padding: 6px 16px;
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px #f2f2f2 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: pointer;
}

.end-chat-btn-disable {
    padding: 6px 16px;
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px #f2f2f2 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: not-allowed;
}

.end-chat-btn-title {
    text-align: center;
    color: #004d62;
    font-size: 13px;
    line-height: 16px;
    word-wrap: break-word
}
</style>

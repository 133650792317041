<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="gpt-main-item">
        <div id="topic-scroll-container__w" ref="scrollContainer" class="topic-scroll-container">
            <div
                style="font-size: 16px; padding: 0 10px 10px 10px; border-bottom: 1px solid #f2f2f2; font-weight: bold; color: #004d62">
                {{ $t('Category') }}</div>
            <div style="height: 180px; overflow-y: scroll;" class="main-item-list">
                <div v-for="(item, index) in mainItem" :id="'main-item__' + index" :key="index" class="main-item_msg"
                    @click="handleChosesMainItem(item)">
                    <div class="gpt-main-item__content">
                        {{ item?.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mainItem: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    methods: {
        handleChosesMainItem(item) {
            item.user_session = localStorage.getItem('user-session')
            this.$eventBus.publish('handle-choses-main-item', item)
        },
    },
}
</script>
<style scope>
.gpt-main-item {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    left: 10px;
    bottom: 135px;
    z-index: 9999999;
    border-radius: 10px;
    width: 250px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border: 1px solid #ecedee;
}

.topic-scroll-container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    padding: 10px 0;
}

.main-item_msg {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    padding: 10px;
    font-size: 13px;
    color: #373737;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
}

.main-item_msg:hover {
    background-color: #f2f2f2;
    color: #004d62;
}

.main-item-list::-webkit-scrollbar {
    z-index: 11;
    width: 4px;
}

.main-item-list::-webkit-scrollbar:horizontal {
    height: 4px;
}

.main-item-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 4px;
    background: #b4bccc;
}

.main-item-list::-webkit-scrollbar-corner {
    background: #fff;
}

.main-item-list::-webkit-scrollbar-track {
    background: #fff;
}

.main-item-list::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 4px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .gpt-main-item {
        right: 130px;
        left: unset;
        bottom: 170px;
    }
}
</style>

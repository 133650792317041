<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img v-if="checkShow" style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;" :src="botAvatar" />

                <div v-else style="width: 32px;"></div>
            </div>
            <div class="gpt-yes-no-message">
                <div class="gpt-yes-no-message__content" style="">{{ $t('Was your question resolved?') }}</div>
                <div v-if="checkDisable" class="gpt-yes-no-message__wrap">
                    <div class="gpt-yes-no-message__item-disable gpt-yes-no-message__item-yes">
                        {{ $t('Yes') }}
                    </div>
                    <div class="gpt-yes-no-message__item-disable gpt-yes-no-message__item-no">
                        {{ $t('No') }}
                    </div>
                </div>
                <div v-else class="gpt-yes-no-message__wrap">
                    <div class="gpt-yes-no-message__item gpt-yes-no-message__item-yes"
                        @click="handleYesNoMsg($t('はい'))">
                        {{ $t('Yes') }}
                    </div>
                    <div class="gpt-yes-no-message__item gpt-yes-no-message__item-no"
                        @click="handleYesNoMsg($t('いいえ'))">
                        {{ $t('No') }}
                    </div>
                </div>
                <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        checkShow: {
            type: Boolean,
            required: true,
        },
        checkDisable: {
            type: Boolean,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    computed: {},
    methods: {
        handleYesNoMsg(action) {
            const params = {
                message: action,
            }
            this.$eventBus.publish('click-yes-no-message', params)
        },
    },
}
</script>

<style>
.gpt-yes-no-message {
    flex-grow: 1;
}

.gpt-yes-no-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-yes-no-message__wrap {
    display: flex;
}

.gpt-yes-no-message__item {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: pointer;
}
.gpt-yes-no-message__item-disable {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: not-allowed;
}

.gpt-yes-no-message__item-yes {
    background-color: #009c77;
    color: white;
}

.gpt-yes-no-message__item-no {
    background-color: #e21124;
    color: white;
}
</style>

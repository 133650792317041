<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div style="display: flex; flex-direction: column; justify-content: space-between">
        <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-top: 1px solid #e2e2e2;
            ">
            <img v-if="isDisabled" style="margin-right: 10px; width: 24px; cursor: not-allowed;"
                :src="getImage('list.svg')" />
            <img v-else style="margin-right: 10px; width: 24px; cursor: pointer;" :src="getImage('list-active.svg')"
                @click="toggleMainItem" />
            <div style="position: relative; flex-grow: 1">
                <div style="border: 1px solid #ccc; border-radius: 5px; display: flex;">
                    <textarea v-model="textContent" style="color: #373737" @input="adjustHeight" class="auto-expand" rows="1"
                        ref="autoExpandTextarea" :placeholder="$t('Please type message')" :disabled="isDisabled"
                        @keydown="handleKeyDown"></textarea>
                </div>
                <img v-if="!isBlank"
                    style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"
                    :src="getImage('send.svg')" @click="submitText()" />
                <img v-else style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%)"
                    :src="getImage('un-send.svg')" />
            </div>
        </div>
        <div style="
                opacity: 0.7;
                text-align: center;
                color: #444654;
                font-size: 11px;
                font-weight: 400;
                line-height: 24px;
                word-wrap: break-word;
                padding: 0 10px 10px 10px;
            ">{{ $t('RChat qoute') }}</div>
    </div>
</template>

<script>
import debounceMixin from '../Mixins/debounceMixin.js';
export default {
    mixins: [debounceMixin],
    data() {
        return {
            textContent: '',
            isBlank: true,
            disableMainItem: true,
            activeSVG: 'list.svg',
            isDisabled: localStorage.getItem('user-session-is-set') !== null ? false : true
        }
    },
    watch: {
        textContent(val) {
            let checkBlank = val.replace(/\s+/g, ' ').trim().length == 0
            this.isBlank = checkBlank ? true : false
        },
    },
    mounted() {
        this.$eventBus.subscribe('enable-chat-input', this.handleEnableChatInput);
        this.$nextTick(() => {
            if (this.$refs.autoExpandTextarea) {
                this.adjustHeight({ target: this.$refs.autoExpandTextarea });
            }
        });
    },
    unmounted() {
        this.$eventBus.unsubscribe('enable-chat-input', this.handleEnableChatInput);
    },
    methods: {
        submitText() {
            let message = this.textContent
            let newStr = message.replace(/\n/g, "<br>");
            if (newStr === '') return
            const params = {
                message: newStr,
            }
            this.$eventBus.publish('click-button-send-guest-message', params)
            this.textContent = ''
            this.$refs.autoExpandTextarea.style.height = 'auto'; // Đặt chiều cao tự động trước để reset
        },
        toggleMainItem(event) {
            this.$eventBus.publish('click-button-toogle-main-item', event)
            this.disableMainItem = !this.disableMainItem
            this.activeSVG = this.disableMainItem ? 'list.svg' : 'list-active.svg'
        },
        handleEnableChatInput() {
            this.isDisabled = false
        },
        handleKeyDown(event) {
            if (event.shiftKey && event.keyCode === 13) {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const currentValue = event.target.value;
                const newValue = currentValue.slice(0, cursorPosition) + "\n" + currentValue.slice(cursorPosition);
                event.target.value = newValue;
                this.adjustHeightSE(event)
            } else if (event.keyCode == 13) {
                event.preventDefault();
                this.debounce(() => {
                    this.submitText()
                }, 300);
                event.target.style.height = 'auto'; // Đặt chiều cao tự động trước để reset
            }
            // }, 500),
        },
        adjustHeight(event) {
            const element = event.target;
            element.style.height = 'auto'; // Đặt chiều cao tự động trước để reset
            element.style.height = element.scrollHeight == 0 ? 'auto' : element.scrollHeight + 'px'; // Cập nhật chiều cao dựa trên scrollHeight
        },
        adjustHeightSE(event) {
            const element = event.target;
            element.style.height = 'auto'; // Đặt chiều cao tự động trước để reset
            element.style.height = element.scrollHeight == 0 ? 'auto' : element.scrollHeight + 'px'; // Cập nhật chiều cao dựa trên scrollHeight
            element.scrollTop = element.scrollHeight;
        },
    },
}
</script>
<style>
.auto-expand {
    resize: none;
    box-sizing: border-box;
    min-height: 20px;
    max-height: 90px;
    width: 86%;
    margin: 5px;
    border: none !important;
    border-radius: 4px;
    overflow-y: auto;
    line-height: 1.5;
    font-family: 'Noto Sans Japanese', 'Yu Gothic', YuGothic, sans-serif !important;
    font-style: normal !important;
    font-size: 13px !important;
    caret-color: #004d62;
    padding: 2px;
    display: block !important;
}

.auto-expand:focus-visible {
    outline: none;
}

.auto-expand::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.auto-expand::-webkit-scrollbar:horizontal {
    height: 6px;
}

.auto-expand::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.auto-expand::-webkit-scrollbar-corner {
    background: #fff;
}

.auto-expand::-webkit-scrollbar-track {
    background: #fff;
}

.auto-expand::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.auto-expand:focus-visible {
    outline: none
}
</style>

import './_init.js'
import { createApp } from 'vue'
import EventBus from './helper/EventBus'
import AppComponent from './views/AppComponent.vue'
import AxiosService from './Utils/axios'
import i18n from './ThirdParty/i18n'
import myMixin from './Mixins/myMixin'
import chatbotStyles from './Utils/chatbotStyles.js'

// Tạo và cấu hình Vue app
const app = createApp(AppComponent)
app.mixin(myMixin)
app.use(i18n)
app.config.globalProperties.$eventBus = EventBus
app.config.globalProperties.$axios = new AxiosService()

const {
    dataset: { botTenantId },
} = document.currentScript
window.chatGPTBotTenantId = botTenantId
const {
    dataset: { botCode },
} = document.currentScript
window.chatGPTBotCode = botCode

class ChatBotElement extends HTMLElement {
    constructor() {
        super()
        const shadowRoot = this.attachShadow({ mode: 'open' })

        // Tạo một container cho Vue app trong Shadow DOM
        const container = document.createElement('div')
        container.id = 'gpt-chat-box'
        shadowRoot.appendChild(container)

        // Tạo phần tử style và thêm vào shadowRoot
        const style = document.createElement('style')
        style.textContent = chatbotStyles
        shadowRoot.appendChild(style)

        // Mount Vue app vào container
        app.mount(container)
    }
}

// Đăng ký custom element
customElements.define('chatbot-app', ChatBotElement)

// Tự động thêm chatbot vào trang khi document sẵn sàng
document.addEventListener('DOMContentLoaded', () => {
    const existingElement = document.querySelector('chatbot-app')
    if (!existingElement) {
        const chatBotElement = document.createElement('chatbot-app')
        chatBotElement.id = 'my-shadow-dom-chat-bot'
        document.body.appendChild(chatBotElement)
    }
})

// debounceMixin.js
export default {
    data() {
        return {
            debounceTimer: null,
        }
    },
    methods: {
        debounce(func, wait) {
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(func, wait)
        },
    },
}

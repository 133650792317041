<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="gpt-user-message" style="align-items: end">
        <p class="gpt-user-message__content" style="margin: 0; word-break: break-word;" v-html="userMessage"></p>
        <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed: {
        userMessage() {
            if (!this.message.message?.text) return
            var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
            return this.message.message?.text.replace(urlRegex, function (url) {
                var hyperlink = url
                // eslint-disable-next-line no-useless-escape
                if (!hyperlink.match('^https?:\/\/')) {
                    hyperlink = 'http://' + hyperlink
                }
                return (
                    '<a ' +
                    'style="color: white;' +
                    'text-decoration: underline;"' +
                    ' href="' +
                    hyperlink +
                    '" target="_blank" rel="noopener noreferrer">' +
                    url +
                    '</a>'
                )
            })
        },
    },
    mounted() {},
    methods: {},
}
</script>
<style>
.gpt-user-message {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
}

.gpt-user-message__content {
    background-color: #004D63;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: white;
}

.gpt-user-message__last {
    background-color: #004D63;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: white;
}
</style>

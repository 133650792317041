<template>
    <div v-show="isOpenPopup" id="sc-chat-popup" class="sc-chat">
        <ChatHeader style="z-index: 99999;"></ChatHeader>
        <MessageList v-if="!isblocked" id="message-list" :bot-avatar="botAvatar" :check-disable="checkDisable">
        </MessageList>
        <Block v-else></Block>
        <UserInput v-if="!isblocked && !isReconnect"> </UserInput>
        <UserInputBlock v-else> </UserInputBlock>
    </div>
</template>

<script>
import ChatHeader from './Header.vue'
import MessageList from './MessageList.vue'
import Block from './Block.vue'
import UserInput from './UserInput.vue'
import UserInputBlock from './UserInputBlock.vue'

export default {
    components: {
        ChatHeader,
        MessageList,
        Block,
        UserInput,
        UserInputBlock,
    },
    props: {
        isOpenPopup: {
            type: Boolean,
            default: false,
        },
        checkDisable: {
            type: Boolean,
            default: false,
        },
        mainItem: {
            type: Object,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            isblocked: false,
            isReconnect: false,
        }
    },
    watch: {
        isOpenPopup(newVal) {
            this.$nextTick(() => {
                var hostElement = document.getElementById('my-shadow-dom-chat-bot');
                var shadowRoot = hostElement.shadowRoot;
                var scChatPopup = shadowRoot.getElementById('sc-chat-popup');
                if (newVal) {
                    scChatPopup.classList.add('show');
                } else {
                    scChatPopup.classList.remove('show');
                }
            });
        },
    },
    async mounted() {
        this.$eventBus.subscribe('handle-user-is-block', this.handleUserIsBlock)
        this.$eventBus.subscribe('block-user-chat-event', this.handleUserIsBlockRealTime)
        this.$eventBus.subscribe('reconnect-chatgpt', this.reConnectChat)
        this.$eventBus.subscribe('reconnected', this.reConnected)
    },
    unmounted() {
        this.$eventBus.unsubscribe('handle-user-is-block', this.handleUserIsBlock)
        this.$eventBus.unsubscribe('block-user-chat-event', this.handleUserIsBlock)
    },

    methods: {
        handleChoseMainItem(params) {
            this.$eventBus.publish('enable-end-chat-btn')
            this.$eventBus.publish('handle-main-item-content', params)
        },
        handleUserIsBlock() {
            this.isblocked = true
        },
        handleUserIsBlockRealTime(data) {
            this.isblocked = data.data.is_block == true
        },
        reConnectChat() {
            this.isReconnect = true
        },
        reConnected() {
            this.isReconnect = false
        }
    },
}
</script>

<style>
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.sc-chat {
    z-index: 1000000;
    background-color: white;
    position: fixed;
    right: calc(1% / 2);
    bottom: 0px;
    width: calc(100% - 2px);
    height: calc(100dvh - 1px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transform: scale(0);
    transform-origin: bottom right;
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    animation: slideUp 0.4s ease-out forwards;
}

.sc-chat.show {
    opacity: 1;
    /* Ensure fully visible when shown */
    transform: scale(1);
}

#sc-chat-popup {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 8px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}
</style>

<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div style="display: flex; flex-direction: column; justify-content: space-between">
        <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-top: 1px solid #e2e2e2;
            ">
            <img style="margin-right: 10px; width: 24px; cursor: not-allowed;" :src="getImage('list.svg')" />
            <div style="position: relative; flex-grow: 1; cursor: not-allowed;">
                <div style="border: 1px solid #ccc; border-radius: 5px; display: flex;">
                    <textarea class="auto-expand" rows="1" ref="autoExpandTextarea"
                        :placeholder="$t('Please type message')" disabled style="cursor: not-allowed;"></textarea>
                </div>
                <img style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%)"
                    :src="getImage('un-send.svg')" />
            </div>
        </div>
        <div style="
                opacity: 0.7;
                text-align: center;
                color: #444654;
                font-size: 11px;
                font-weight: 400;
                line-height: 24px;
                word-wrap: break-word;
                padding: 0 10px 10px 10px;
            ">{{ $t('RChat qoute') }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textContent: '',
        }
    },
}
</script>
<style>
.user-input {
    font-size: 13px;
    color: #444654;
}

.user-input:focus-visible {
    outline: none;
}
</style>

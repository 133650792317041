/* eslint-disable no-unused-vars */
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import axios from 'axios'
import { bindConnectedEvent } from './Listen'

window.originChatGPT = new URL(document.currentScript.src).origin

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'http') === 'https',
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios
                    .post(
                        `${window.originChatGPT}/broadcasting/auth`,
                        {
                            socket_id: socketId,
                            channel_name: channel.name,
                            user_session: localStorage.getItem('user-session'),
                        },
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                            },
                        },
                    )
                    .then((response) => {
                        callback(null, response.data)
                    })
                    .catch((error) => {
                        callback(error)
                    })
            },
        }
    },
})

import { createI18n } from 'vue-i18n'

// Import translation files
import ja from '../lang/ja.json'
import en from '../lang/en.json'
import cn from '../lang/cn.json'
import ru from '../lang/ru.json'

// Create i18n instance
const i18n = createI18n({
    locale: (navigator.language === 'ja' || navigator.language === 'ja-JP') ? 'ja' : 'en', // set default locale
    fallbackLocale: 'en', // set fallback locale
    messages: { ja, en, cn, ru }, // set locale messages
    silentTranslationWarn: true,
})

export default i18n

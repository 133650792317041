const EventBus = {
    subscribe(event_name, callback) {
        // Định nghĩa một wrapper function để giữ tham chiếu đến callback
        const eventHandler = function(e) {
            callback(e.detail);
        }

        // Lưu trữ tham chiếu đến wrapper function vào callback để sau này có thể hủy đăng ký
        callback._eventHandler = eventHandler;

        // Đăng ký sự kiện với wrapper function
        document.addEventListener(event_name, eventHandler);
    },
    unsubscribe(event_name, callback) {
        // Sử dụng tham chiếu được lưu trữ để hủy đăng ký
        if (callback._eventHandler) {
            document.removeEventListener(event_name, callback._eventHandler);

            // Xóa tham chiếu sau khi hủy đăng ký để tránh memory leak
            delete callback._eventHandler;
        }
    },
    publish(event_name, params) {
        var event = new CustomEvent(event_name, {
            detail: params,
        });
        document.dispatchEvent(event);
    },
}

export default EventBus;

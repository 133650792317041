<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img v-if="checkShow" style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;" :src="botAvatar" />

                <div v-else style="width: 32px;"></div>
            </div>
            <div class="gpt-contact-message">
                <div class="gpt-contact-message__content">
                    <span> サポートに連絡をしてください: </span>
                    <div>
                        <a style="color: #fff500" href="">Mail</a> または <a style="color: #fff500" href="">Zendesk</a>
                    </div>
                </div>
                <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        checkShow : {
            type: Boolean,
            required: true,
        },
        botAvatar : {
            type: String,
            required: true,
        }
    },
    computed: {},
    methods: {},
}
</script>

<style>
.gpt-contact-message {
    flex-grow: 1;
}

.gpt-contact-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
}

.gpt-contact-message__wrap {
    display: flex;
}

.gpt-contact-message__item {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 4px 8px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    line-height: 1;
    cursor: pointer;
}
</style>

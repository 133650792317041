<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img v-if="checkShow" style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;" :src="botAvatar" />

                <div v-else style="width: 32px;"></div>
            </div>
            <div class="gpt-image-message__content">
                <img style="max-width: 50%; border-radius: 10px; cursor: pointer; box-shadow: 0 0 5px #00000026;"
                    :src="imgURL" @click="imageView(imgURL)" @load="handleImageLoad"/>
                <div v-if="message.message.buttons.length > 0">
                    <div v-for="btn in message.message.buttons" :key="btn.next_block_id" class="card-btn"
                        @click="handleNextBlock(btn)">{{ btn?.text }}</div>
                </div>
            </div>
            <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        checkShow: {
            type: Boolean,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    computed: {
        imgURL() {
            return this.message.message?.file_url
        },
    },
    methods: {
        handleNextBlock(btn) {
            btn.user_session = localStorage.getItem('user-session')
            this.$eventBus.publish('show-typing')
            this.$eventBus.publish('click-next-block-btn', btn)
        },
        imageView(url) {
            let params = {
                'url': url,
                'file_name': this.message.message.file_name
            }
            this.$eventBus.publish('show-image', params)
        },
        handleImageLoad() {
            this.$eventBus.publish('scroll-to-bottom-start');
        }
    },
}
</script>
<style>
.demo-image {
    max-width: 85%;
    background-size: contain;
    background-repeat: no-repeat;
}

.gpt-image-message__content {
    /* padding: 5px; */
    display: flex;
    align-items: center;
    border-radius: 8px;
}
</style>

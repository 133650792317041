<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="gpt-block-item">
        <img style="width: 100px; margin-bottom: 10px;" :src="getImage('blocked.svg')"/>
        <div style="font-size: 12px; color: #444654">
            {{ $t('You have been blocked by the administrator') }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
    },
}
</script>
<style>
.gpt-block-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>

<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;" :src="botAvatar" />
            </div>
            <div class="gpt-text-message">
                <div class="gpt-text-message__content gpt-message__typing">
                    <div class="loader"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    computed: {},
    methods: {},
}
</script>

<style>
.gpt-message__typing {
    height: 19px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
    box-sizing: content-box;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 45px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #004d63 090%, #004d6300);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1300ms infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}
</style>

const chatbotStyles = `
.sc-logo {
    z-index: 1000000;
    position: fixed;
    right: 25px;
    bottom: 25px;
}

.sc-logo img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
}

.chatgpt-img-view {
    z-index: 1000001;
    position: fixed;
    right: 0;
    top: 0;
    background: #0000007d;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeInScaleUp {
    from {
        opacity: 0;
        transform: scale(0.1);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.chatgpt-image {
    width: 75%;
    animation: fadeInScaleUp 400ms ease-out;
}

@media (min-width: 768px) and (max-width: 991px) {
    .sc-logo img {
        width: 50px !important;
        height: 50px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .sc-logo img {
        width: 55px !important;
        height: 55px !important;
    }
}

@media (min-width: 1200px) {
    .sc-logo img {
        width: 70px !important;
        height: 70px !important;
    }
}
.sc-app {
    font-family: 'Noto Sans Japanese', 'Yu Gothic', YuGothic, sans-serif !important;
    font-style: normal !important;
}
.user-input {
    font-size: 13px;
    color: #444654;
}

.user-input:focus-visible {
    outline: none;
}
.auto-expand {
    resize: none;
    box-sizing: border-box;
    min-height: 20px;
    max-height: 90px;
    width: 86%;
    margin: 5px;
    border: none !important;
    border-radius: 4px;
    overflow-y: auto;
    line-height: 1.5;
    font-family: 'Noto Sans Japanese', 'Yu Gothic', YuGothic, sans-serif !important;
    font-style: normal !important;
    font-size: 13px !important;
    caret-color: #004d62;
    padding: 2px;
    display: block !important;
}

.auto-expand:focus-visible {
    outline: none;
}

.auto-expand::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.auto-expand::-webkit-scrollbar:horizontal {
    height: 6px;
}

.auto-expand::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.auto-expand::-webkit-scrollbar-corner {
    background: #fff;
}

.auto-expand::-webkit-scrollbar-track {
    background: #fff;
}

.auto-expand::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.auto-expand:focus-visible {
    outline: none
}
.chat-gpt-user-info {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.chat-gpt-user-info__wrap {
    width: 80%;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.7) 0px 8px 24px;
}

.chat-gpt-user-info__title {
    color: #2d2d2d;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 28.8px;
    word-wrap: break-word;

}

.chat-gpt-user-info__name {
    color: #282828;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.8px;
    word-wrap: break-word;
    display: block;
    margin-bottom: 8px;
}

.chat-gpt-user-info__email {
    color: #282828;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.8px;
    word-wrap: break-word;
    display: block;
    margin-bottom: 8px;
}

.chat-gpt-user-info__btn {
    line-height: 1;
    padding: 15px;
    background-color: #004D63;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.user-info-input {
    padding-block: 0;
    padding-inline: 5px;
}

.user-info-input:focus-visible {
    outline: none;
}
.msg-list::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.msg-list::-webkit-scrollbar:horizontal {
    height: 6px;
}

.msg-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.msg-list::-webkit-scrollbar-corner {
    background: #fff;
}

.msg-list::-webkit-scrollbar-track {
    background: #fff;
}

.msg-list::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.time-day {
    color: #8A8A8A;
    font-size: 12px;
    margin: 0;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 7px;
    background: white;
    border: 1px solid #e2e2e2;
    border-radius: 50px;
}

.reconect-btn {
    text-align: center;
    color: #004d62;
    font-size: 13px;
    line-height: 16px;
    word-wrap: break-word;
    padding: 6px 16px;
    background: #fff;
    box-shadow: 0 0 8px #004d6266;
    border-radius: 5px;
    border: 1px #004d62 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
}
.msg-time {
    font-size: 11px;
    font-weight: 400;
    color: #8a8a8a;
    margin-bottom: 15px;
}

.bot-msg-time {
    padding-left: 52px;
}

.user-msg-time {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.bot-massage {
    margin-bottom: 8px;
}
.gpt-main-item {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    left: 10px;
    bottom: 135px;
    z-index: 9999999;
    border-radius: 10px;
    width: 250px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border: 1px solid #ecedee;
}

.topic-scroll-container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    padding: 10px 0;
}

.main-item_msg {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    padding: 10px;
    font-size: 13px;
    color: #373737;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
}

.main-item_msg:hover {
    background-color: #f2f2f2;
    color: #004d62;
}

.main-item-list::-webkit-scrollbar {
    z-index: 11;
    width: 4px;
}

.main-item-list::-webkit-scrollbar:horizontal {
    height: 4px;
}

.main-item-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 4px;
    background: #b4bccc;
}

.main-item-list::-webkit-scrollbar-corner {
    background: #fff;
}

.main-item-list::-webkit-scrollbar-track {
    background: #fff;
}

.main-item-list::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 4px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .gpt-main-item {
        right: 130px;
        left: unset;
        bottom: 170px;
    }
}
.lds-ring-msg {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-ring-msg div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #004d63;
    border-radius: 50%;
    animation: lds-ring-msg 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #004d63 transparent transparent transparent;
}

.lds-ring-msg div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring-msg div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring-msg div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring-msg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.gpt-rate-conversation {
    z-index: 1000000;
    background-color: #0000004d;
    /* position: fixed;
    right: 25px;
    bottom: 25px;
    width: 400px;
    height: 700px; */
    position: fixed;
    right: calc(1% / 2);
    bottom: 0px;
    width: calc(100% - 2px);
    height: calc(100dvh - 1px);
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #004d63;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #004d63 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 600px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}
.list-languages::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #e2e2e2;
    position: absolute;
    top: -10px;
    right: 20px;
}

.national-option {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    border-radius: 3px;
}

.national-option:hover {
    background: #e2e2e282;
}

.national-name {
    font-size: 12px;
}

.national-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.flag-active {
    color: #004d62;
    font-weight: bold;
}
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.sc-chat {
    z-index: 1000000;
    background-color: white;
    position: fixed;
    right: calc(1% / 2);
    bottom: 0px;
    width: calc(100% - 2px);
    height: calc(100dvh - 1px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transform: scale(0);
    transform-origin: bottom right;
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    animation: slideUp 0.4s ease-out forwards;
}

.sc-chat.show {
    opacity: 1;
    /* Ensure fully visible when shown */
    transform: scale(1);
}

#sc-chat-popup {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 8px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .sc-chat {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

.gpt-rate-conversation {
    z-index: 1000000;
    background-color: #0000004d;
    position: fixed;
    right: 25px;
    bottom: 25px;
    transition: box-shadow 0.2s ease-in-out;
    width: 400px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.active-option {
    border-color: #004D63 !important;
}

.need-help-option {
    border-color: #004D63 !important;
    color: #004D63 !important;
    font-weight: bold !important;
}

.rate-text {
    font-family: Noto Sans Japanese, Yu Gothic, YuGothic, sans-serif !important;
    font-style: normal !important;
}

.rate-text:focus-visible {
    outline: none;
}

.rate-text::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.rate-text::-webkit-scrollbar:horizontal {
    height: 6px;
}

.rate-text::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.rate-text::-webkit-scrollbar-corner {
    background: #fff;
}

.rate-text::-webkit-scrollbar-track {
    background: #fff;
}

.rate-text::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.active-rate {
    border-color: #004D63;
}
.gpt-block-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gpt-yes-no-message {
    flex-grow: 1;
}

.gpt-yes-no-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-yes-no-message__wrap {
    display: flex;
}

.gpt-yes-no-message__item {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: pointer;
}
.gpt-yes-no-message__item-disable {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: not-allowed;
}

.gpt-yes-no-message__item-yes {
    background-color: #009c77;
    color: white;
}

.gpt-yes-no-message__item-no {
    background-color: #e21124;
    color: white;
}
.gpt-user-message {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
}

.gpt-user-message__content {
    background-color: #004D63;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: white;
}

.gpt-user-message__last {
    background-color: #004D63;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: white;
}
.gpt-message__typing {
    height: 19px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
    box-sizing: content-box;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 45px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #004d63 090%, #004d6300);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1300ms infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}
.gpt-text-message {
    flex-grow: 1;
}

.gpt-text-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-text-message__content p {
    margin: 0;
}

.card-btn {
    word-break: break-word;
    padding: 5px;
    border: 1px solid rgb(202, 202, 202, 75%);
    border-radius: 5px;
    background-color: #ffffff;
    color: #004d62;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px #00000029;
    text-align: center;
    line-height: 1.5;
}
.gpt-text-message {
    flex-grow: 1;
}

.gpt-text-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-text-message__content p {
    margin: 0;
}

.card-btn {
    word-break: break-word;
    padding: 5px;
    border: 1px solid rgb(202, 202, 202, 75%);
    border-radius: 5px;
    background-color: #ffffff;
    color: #004d62;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px #00000029;
    text-align: center;
    line-height: 1.5;
}
.demo-image {
    max-width: 85%;
    background-size: contain;
    background-repeat: no-repeat;
}

.gpt-image-message__content {
    /* padding: 5px; */
    display: flex;
    align-items: center;
    border-radius: 8px;
}
.gpt-yes-no-message {
    flex-grow: 1;
}

.gpt-yes-no-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-yes-no-message__wrap {
    display: flex;
    margin-top: 10px;
}

.gpt-yes-no-message__item {
    margin-right: 4px;
    font-size: 12px;
    color: #282828;
    padding: 12px 16px;
    border-radius: 5px;
    line-height: 1;
    cursor: pointer;
}

.gpt-yes-no-message__item-yes {
    background-color: #009c77;
    color: white;
}

.gpt-yes-no-message__item-no {
    background-color: #e21124;
    color: white;
}

.end-chat-btn {
    padding: 6px 16px;
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px #f2f2f2 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: pointer;
}

.end-chat-btn-disable {
    padding: 6px 16px;
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px #f2f2f2 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: not-allowed;
}

.end-chat-btn-title {
    text-align: center;
    color: #004d62;
    font-size: 13px;
    line-height: 16px;
    word-wrap: break-word
}

textarea:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
}
`
export default chatbotStyles

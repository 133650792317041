<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/no-unused-components -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div id="msg-list-scroll" style="flex-grow: 1; overflow-y: scroll; padding: 5px 0" class="msg-list"
        :style="{ 'position': messages.length == 0 ? 'relative' : 'unset' }" @scroll="handleScroll">
        <LoadingMSG v-show="isLoadMoreMSG"></LoadingMSG>
        <div v-if="messages.length != 0">
            <div v-for="(item, index) in groupMessage" :key="index">
                <div style="border-bottom: 1px solid #E2E2E2; position: relative; margin: 15px 0 20px 5px;">
                    <p class="time-day">{{ formatTime(index) }}</p>
                </div>
                <Message v-for="(message, idx) in item" :key="idx" :msg-index="idx" :message="message" :list-msg="item"
                    :bot-avatar="botAvatar" :check-disable="checkDisable">
                </Message>
            </div>
        </div>
        <div v-show="isReconnectGpt"
            style="display: flex; justify-content: center; align-items: center; height: 100%; flex-direction: column;">
            <div style="opacity: 0.7; text-align: center; color: rgb(68, 70, 84); font-size: 12px;
                font-weight: 400; line-height: 24px; overflow-wrap: break-word; padding: 0px 30px 10px; ">
                {{ $t('reconnect notice') }}
            </div>
            <div class=" reconect-btn" @click="reload()">
                {{ $t('reconnect') }}
            </div>
        </div>
        <UserInfo v-if="isBlankUser" />
        <TypingMsg v-if="isTyping" :bot-avatar="botAvatar"></TypingMsg>
    </div>
</template>

<script>
import Message from './Message.vue';
import UserInfo from './UserInfo.vue';
import TypingMsg from './Messages/TypingMsg.vue'
import LoadingMSG from './LoadingMSG.vue'

export default {
    components: {
        Message,
        UserInfo,
        TypingMsg,
        // eslint-disable-next-line vue/no-unused-components
        LoadingMSG
    },
    props: {
        botAvatar: {
            type: String,
            required: true,
        },
        checkDisable: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            messages: [],
            isBlankUser: (localStorage.getItem('user-session') !== null && localStorage.getItem('user-session-is-set') !== null) ? false : true,
            errorUserInfo: [],
            isTyping: false,
            isLoadMoreMSG: false,
            existingMessageIds: new Set(),
            isReconnectGpt: false
        };
    },
    computed: {
        groupMessage() {
            return this.groupBy(this.messages, (c) => c.created_at.split(' ')[0].trim());
        },
    },
    watch: {
        messages(val) {
            if (val.length > 0) {
                this.gotoBottomChat()
            }
        }
    },
    async mounted() {
        if (localStorage.getItem('user-session') !== null) {
            this.getHistoryMessage()
        }
        this.$eventBus.subscribe('click-button-send-guest-message', this.handleClickButtonSendGuestMessage);
        this.$eventBus.subscribe('click-yes-no-message', this.handleClickYesNoMessage);
        this.$eventBus.subscribe('click-end-chat-btn', this.handleClickEndChatBtn);
        this.$eventBus.subscribe('start-chat', this.handleClickStartChat);
        this.$eventBus.subscribe('show-typing', this.handleShowTyping)
        this.$eventBus.subscribe('hide-typing', this.handleHiddenTyping)
        this.$eventBus.subscribe('handle-main-item-content', this.handleMainItemContent);
        this.$eventBus.subscribe('click-next-block-btn', this.handleNextBlock)
        this.$eventBus.subscribe('get-force-end-chat-msg', this.getForceEndChatMsg)
        this.$eventBus.subscribe('render-topic-msg', this.renderTopicMsg)
        this.$eventBus.subscribe('get-yes-no-msg', this.getYesNoMsg)
        this.$eventBus.subscribe('add-msg', this.addMessageBeforeSend)
        this.$eventBus.subscribe('thank-quote', this.thankQuote)
        this.$eventBus.subscribe('clear-history-real-time', this.clearHistoryRealTime)
        this.$eventBus.subscribe('msg-list-start-msg', this.msgListStartMsg)
        this.$eventBus.subscribe('msg-advice-handle-msg', this.msgAdviceHandleMsg)
        this.$eventBus.subscribe('scroll-to-bottom-start', this.gotoBottomChat)
    },
    unmounted() {
        this.$eventBus.unsubscribe('click-button-send-guest-message', this.handleClickButtonSendGuestMessage);
        this.$eventBus.unsubscribe('click-yes-no-message', this.handleClickYesNoMessage);
        this.$eventBus.unsubscribe('click-end-chat-btn', this.handleClickEndChatBtn);
        this.$eventBus.unsubscribe('start-chat', this.handleClickStartChat);
        this.$eventBus.unsubscribe('show-typing', this.handleShowTyping)
        this.$eventBus.unsubscribe('hide-typing', this.handleHiddenTyping)
        this.$eventBus.unsubscribe('handle-main-item-content', this.handleMainItemContent);
        this.$eventBus.unsubscribe('click-next-block-btn', this.handleNextBlock)
        this.$eventBus.unsubscribe('get-force-end-chat-msg', this.getForceEndChatMsg)
        this.$eventBus.unsubscribe('render-topic-msg', this.renderTopicMsg)
        this.$eventBus.unsubscribe('get-yes-no-msg', this.getYesNoMsg)
        this.$eventBus.unsubscribe('add-msg', this.addMessageBeforeSend)
    },
    methods: {
        gotoBottomChat() {
            this.$nextTick(() => {
                var hostElement = document.getElementById('my-shadow-dom-chat-bot');
                var shadowRoot = hostElement.shadowRoot;
                const elMsgList = shadowRoot.getElementById('message-list');
                elMsgList.scrollTop = 1000000000;
            });
        },
        handleScroll(e) {
            if (this.messages.length === 0) {
                return;
            }
            if (e.target.scrollTop === 0) {
                if (localStorage.getItem('load-full-history') == 'true') return
                this.isLoadMoreMSG = true
                let params = {
                    'user_session': localStorage.getItem('user-session'),
                    'limit': 20,
                    'cursor_id': this.messages[0].id
                }
                this.$axios.get('get-history', params)
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoadMoreMSG = false
                        }, 1000);
                        if (res.data.length > 0) {
                            this.processMoreMessages(res.data)
                        } else {
                            localStorage.setItem('load-full-history', true)
                        }
                    })
                    .catch(error => console.error('Đã xảy ra lỗi khi gọi API: ' + error));
            }
        },
        async processMoreMessages(messages, isAdvice = false) {
            var hostElement = document.getElementById('my-shadow-dom-chat-bot');
            var shadowRoot = hostElement.shadowRoot;
            const elMsgList = shadowRoot.getElementById('message-list');
            const oldOffetScrollHeight = elMsgList.scrollHeight;
            await messages.forEach((msg) => {
                if (isAdvice) {
                    msg.id = localStorage.getItem('user-session') + (Math.random() + 1).toString(36).substring(7);
                }
                this.messages.unshift(msg);
            });
            const newOffetScrollHight = elMsgList.scrollHeight - 40;
            elMsgList.scrollTop = newOffetScrollHight - oldOffetScrollHeight;
        },
        processMessages(messages, isAdvice = false) {
            messages.forEach((msg) => {
                if (isAdvice) {
                    msg.id = localStorage.getItem('user-session') + (Math.random() + 1).toString(36).substring(7);
                }
                this.messages.unshift(msg);
            });
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
        },
        handleShowTyping() {
            this.isTyping = true
            this.$eventBus.publish('scroll-to-bottom-start')
        },
        handleHiddenTyping() {
            this.isTyping = false
        },
        async getHistoryMessage() {
            let params = {
                'user_session': localStorage.getItem('user-session'),
                'limit': 20
            }
            await this.$axios.get('get-history', params)
                .then((res) => {
                    if (res.data.length === 0 && localStorage.getItem('user-session-is-set') != null) {
                        this.$eventBus.publish('show-typing')
                        this.$eventBus.publish('start-chat', { 'user_session': localStorage.getItem('user-session') })
                    }
                    this.processMessages(res.data)
                })
                .catch(error => console.error('Đã xảy ra lỗi khi gọi API: ' + error));
            this.$nextTick(() => {
                const elMsgList = document.getElementById('message-list');
                elMsgList.scrollTop = 1000000000;
            });
        },
        handleClickStartChat(params) {
            this.$eventBus.publish('show-typing')
            this.getStartMessage(params)
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
        },
        getStartMessage(params) {
            this.isBlankUser = false
            params.locale = this.getLocale(this.$i18n.locale)
            this.$axios.get('get-start-msg', params)
        },
        getLocale(l) {
            switch (l) {
                case 'ja':
                    return 1
                case 'cn':
                    return 2
                case 'ru':
                    return 3
                default:
                    return 4
            }
        },
        async handleNextBlock(dataBtn) {
            this.$eventBus.publish('enable-end-chat-btn')
            let id = (Math.random() + 1).toString(36).substring(7);
            let userMsg = {
                id: id,
                message: {
                    text: dataBtn.text,
                    type: 'text',
                },
                created_at: this.getCurrentDate(),
            }
            this.onMessageWasSent(null, userMsg);
            this.$eventBus.publish('show-typing')
            let userData = {
                'user_session': localStorage.getItem('user-session'),
                'message': userMsg,
                'type': 'user'
            }
            await this.$axios.post('/save-history', userData)
            this.$axios.get('topic/handle-next-block', dataBtn)
                .then((res) => {
                    if (res.data.data.length == 0) {
                        this.$eventBus.publish('hide-typing')
                    }
                })
        },
        async handleMainItemContent(params) {
            let id = (Math.random() + 1).toString(36).substring(7);
            let userMsg = {
                id: id,
                message: {
                    text: params.name,
                    type: 'text',
                },
                created_at: this.getCurrentDate(),
            }
            this.onMessageWasSent(null, userMsg);
            let userData = {
                'user_session': localStorage.getItem('user-session'),
                'message': userMsg,
                'type': 'user'
            }
            await this.$axios.post('/save-history', userData)
            this.$eventBus.publish('show-typing')
            this.getMainItemContent(params)
            this.$eventBus.publish('scroll-to-bottom-start')
        },
        getMainItemContent(params) {
            if (!params || !params.id) {
                console.error('Topic ID is required');
                return;
            }

            this.$axios.get(`/topic/${params.id}/get-start-messages`, {
                'user_session': params.user_session
            })
        },
        async renderFromCards(cards) {
            const messages = [];
            for (const card of cards) {
                let dataCard = {
                    sender_id: 0,
                    message: {
                        text: card.content?.text,
                        buttons: card.content?.buttons,
                        type: card.type,
                        file_url: card.content?.file_url,
                        file_name: card.content?.file_name,
                    },
                    created_at: this.getCurrentDate(),
                }
                messages.push(dataCard);
            }
            this.$eventBus.publish('hide-typing')
            this.messages = [...this.messages, ...messages];
            this.$eventBus.publish('scroll-to-bottom-start')
        },
        async handleClickButtonSendGuestMessage(params) {
            this.$eventBus.publish('enable-end-chat-btn')
            let id = (Math.random() + 1).toString(36).substring(7);
            this.onMessageWasSent(null, {
                id: id,
                message: {
                    text: params.message,
                    type: 'text',
                },
                created_at: this.getCurrentDate(),
            });
            this.$eventBus.publish('show-typing')
            this.sendGuestMessage(params.message, id);
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
        },
        async sendGuestMessage(msg, id) {
            const params = {
                id: id,
                message: {
                    text: msg,
                    type: 'text',
                },
                created_at: this.getCurrentDate(),
                locale: localStorage.getItem('user-locale'),
                user_session: localStorage.getItem('user-session')
            };
            await this.$axios.post('/sent-msg', params)
        },
        async handleClickYesNoMessage(params) {
            let id = (Math.random() + 1).toString(36).substring(7);
            let userMsg = {
                id: id,
                created_at: this.getCurrentDate(),
                message: {
                    text: params.message,
                    type: 'text',
                },
            }
            this.onMessageWasSent(null, userMsg);
            if (params.message !== this.$t('はい')) {
                this.$eventBus.publish('show-typing')
            }
            let userData = {
                'user_session': localStorage.getItem('user-session'),
                'message': userMsg,
                'type': 'user'
            }
            this.$axios.post('/save-history', userData)
            await this.$axios.post('customer-yes-no-msg', {
                type: params.message === this.$t('はい') ? 1 : 0,
                user_session: localStorage.getItem('user-session'),
                locale: this.getLocale(this.$i18n.locale)
            })
                .then((res) => {
                    if (res.data.time_end != null && res.data.is_newest == 0) {
                        setTimeout(() => {
                            this.$eventBus.publish('end-chat')
                        }, params.message !== this.$t('はい') ? 3000 : 500);
                    }
                })
                .catch(error => console.error('Đã xảy ra lỗi khi gọi API: ' + error));
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
        },
        onMessageWasSent(id, message) {
            this.existingMessageIds.add(message.id);

            this.messages = [...this.messages, { id, ...message }];
        },
        handleClickEndChatBtn() {
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
            let params = {
                'user_session': localStorage.getItem('user-session'),
                'user_locale': localStorage.getItem('user-locale'),
            }
            this.$axios.post('/force-end-chat', params)
                .then(() => {
                    this.$eventBus.publish('end-chat')
                })
                .catch(() => {
                });
        },
        renderTopicMsg(data) {
            this.renderFromCards(data)
        },
        getForceEndChatMsg() {
            this.genMsg('end-chat')
        },
        getYesNoMsg() {
            this.genMsg('yes-no')
        },
        genMsg(type) {
            let msg = {
                id: (Math.random() + 1).toString(36).substring(7),
                sender_id: 0,
                created_at: this.getCurrentDate(),
                message: {
                    text: '',
                    type: type,
                }
            }
            this.messages = [...this.messages, { ...msg }];
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
            return msg
        },
        getCurrentDate() {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();
            const hours = String(today.getHours()).padStart(2, '0');
            const minutes = String(today.getMinutes()).padStart(2, '0');

            return `${dd}-${mm}-${yyyy} ${hours}:${minutes}`;
        },
        groupBy(xs, f) {
            return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
        },
        formatTime(date) {
            if (!date) return;
            if (this.isToday(date)) return this.$t('Today');
            let [year, month, day] = date.split('-');

            return `${day}/${month}/${year}`;
        },

        isToday(dateString) {
            const parts = dateString.split('-');
            const inputDate = new Date(parts[2], parts[1] - 1, parts[0]);

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            inputDate.setHours(0, 0, 0, 0);

            return today.getTime() === inputDate.getTime();
        },
        addMessageBeforeSend(msg) {
            if (this.existingMessageIds.has(msg.id)) return;
            this.existingMessageIds.add(msg.id);
            this.messages.push(msg);
            this.$eventBus.publish('hide-typing')
            this.$nextTick(() => {
                this.gotoBottomChat();
            })
        },
        thankQuote() {
            this.handleShowTyping();

            setTimeout(() => {
                let params = {
                    sender_id: 0,
                    message: {
                        text: this.$t('thank_quote'),
                        buttons: [],
                        type: 'text',
                        file_url: '',
                        file_name: '',
                    },
                    created_at: this.getCurrentDate(),
                };

                this.handleHiddenTyping();
                this.messages.push(params);
                this.$eventBus.publish('scroll-to-bottom-start')
            }, 1000);
        }
        ,
        clearHistoryRealTime() {
            this.messages = []
            this.$eventBus.publish('reconnect-chatgpt')
            this.isReconnectGpt = true
            localStorage.setItem('need-reconnect', 1)
        },
        reload() {
            localStorage.setItem('need-reconnect', 0)
            this.$eventBus.publish('reconnected')
            this.isReconnectGpt = false
            this.isTyping = true
            setTimeout(() => {
                this.$axios.get('get-start-msg-1h', {
                    'user_session': localStorage.getItem('user-session'),
                    'locale': this.getLocale(this.$i18n.locale)
                }).then((res) => {
                    this.processMessages(res.data.reverse())
                    this.isTyping = false
                }).catch(() => {
                    this.isTyping = false
                })
            }, 1000);
        },
        msgListStartMsg(data) {
            data.data.forEach((msg) => {
                if (this.existingMessageIds.has(msg.id)) return;
                this.existingMessageIds.add(msg.id);
                this.messages.push(msg);
            });
            this.$eventBus.publish('hide-typing')
        },
        msgAdviceHandleMsg(data) {
            data.message.forEach((msg) => {
                msg.id = localStorage.getItem('user-session') + (Math.random() + 1).toString(36).substring(7);
                if (this.existingMessageIds.has(msg.id)) return;
                this.existingMessageIds.add(msg.id);
                this.messages.push(msg);
                this.$eventBus.publish('hide-typing')
                this.$nextTick(() => {
                    this.gotoBottomChat();
                })
            });
            setTimeout(() => {
                if (data.message[0].order === 3) {
                    this.$eventBus.publish('end-chat')
                }
            }, 3000);

        }
    }
};
</script>

<style>
.msg-list::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.msg-list::-webkit-scrollbar:horizontal {
    height: 6px;
}

.msg-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.msg-list::-webkit-scrollbar-corner {
    background: #fff;
}

.msg-list::-webkit-scrollbar-track {
    background: #fff;
}

.msg-list::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.time-day {
    color: #8A8A8A;
    font-size: 12px;
    margin: 0;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 7px;
    background: white;
    border: 1px solid #e2e2e2;
    border-radius: 50px;
}

.reconect-btn {
    text-align: center;
    color: #004d62;
    font-size: 13px;
    line-height: 16px;
    word-wrap: break-word;
    padding: 6px 16px;
    background: #fff;
    box-shadow: 0 0 8px #004d6266;
    border-radius: 5px;
    border: 1px #004d62 solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
}
</style>

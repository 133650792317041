<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div>
        <TextMessage v-if="message.sender_id == 0 && message.message.type === 'text'" :message="message"
            :bot-avatar="botAvatar" :check-show="checkShowAva()"></TextMessage>
        <TopicTextMessage v-if="message.sender_id == 0 && message.message.type === 'block_message_text'"
            :message="message" :bot-avatar="botAvatar" :check-show="checkShowAva()"></TopicTextMessage>
        <YesNoMessage v-if="message.sender_id == 0 && message.message.type === 'yes-no'" :message="message"
            :bot-avatar="botAvatar" :check-show="checkShowAva()" :check-disable="checkDisable"></YesNoMessage>
        <EndChatMessage v-if="message.sender_id == 0 && message.message.type === 'end-chat'" :message="message"
            :bot-avatar="botAvatar" :check-show="checkShowAva()" :check-disable="checkDisable">
        </EndChatMessage>
        <ContactMessage v-if="message.sender_id == 0 && message.message.type === 'contact'" :message="message"
            :bot-avatar="botAvatar" :check-show="checkShowAva()">
        </ContactMessage>
        <ImageMessage v-if="message.sender_id == 0 && message.message.type === 'block_message_image'" :message="message"
            :bot-avatar="botAvatar" :check-show="checkShowAva()"></ImageMessage>
        <UserMessage v-if="message.sender_id != 0" :message="message"></UserMessage>
        <div v-if="checkShowTime()" class="msg-time"
            :class="{ 'bot-msg-time': message.sender_id == 0, 'user-msg-time': message.sender_id != 0 }">
            {{ formatTime(message?.created_at.split(' ')[0]) + ' ' + message?.created_at.split(' ')[1] }}
        </div>

    </div>
</template>

<script>
import TextMessage from './Messages/TextMessage.vue'
import TopicTextMessage from './Messages/TopicTextMessage.vue'
import YesNoMessage from './Messages/YesNoMessage.vue'
import EndChatMessage from './Messages/EndChatMessage.vue'
import ContactMessage from './Messages/ContactMessage.vue'
import ImageMessage from './Messages/ImageMessage.vue'
import UserMessage from './Messages/UserMessage.vue'

export default {
    components: {
        TextMessage,
        TopicTextMessage,
        YesNoMessage,
        EndChatMessage,
        ContactMessage,
        ImageMessage,
        UserMessage,
    },
    props: {
        msgIndex: {
            type: Number,
            required: true,
        },
        message: {
            type: Object,
            required: true,
        },
        listMsg: {
            type: Object,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        },
        checkDisable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isTyping: false,
            // checkShow: true
        }
    },
    // mounted() {
    // },
    methods: {
        checkShowTime() {
            if (
                this.msgIndex < (this.listMsg.length - 1)) {
                if (this.listMsg[this.msgIndex].sender_id != this.listMsg[this.msgIndex + 1].sender_id) {
                    return true
                }
            }
            if (this.msgIndex == this.listMsg.length - 1 || (this.msgIndex == 0 && this.listMsg.length == 1)) {
                return true
            } else {
                const time1 = new Date(this.convertToISOFormat(this.listMsg[this.msgIndex].created_at));
                const time2 = new Date(this.convertToISOFormat(this.listMsg[this.msgIndex + 1].created_at));

                // Kiểm tra xem hai thời điểm có giống nhau hay không
                const areInSameMinute = time1.getTime() !== time2.getTime();
                this.checkShow = areInSameMinute
                return areInSameMinute
            }
        },
        checkShowAva() {
            if (this.listMsg[this.msgIndex - 1]) {

                if (this.listMsg[this.msgIndex].sender_id != this.listMsg[this.msgIndex - 1].sender_id) {
                    return true
                }
                const time1 = new Date(this.convertToISOFormat(this.listMsg[this.msgIndex].created_at));
                const time2 = new Date(this.convertToISOFormat(this.listMsg[this.msgIndex - 1].created_at));

                const areInSameMinute = time1.getTime() !== time2.getTime();
                return areInSameMinute
            } else {
                return true
            }
        },
        convertToISOFormat(dateTimeStr) {
            const parts = dateTimeStr.split(" ");
            const dateParts = parts[0].split("-");
            const timePart = parts[1];
            return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}:00`;
        },
        formatTime(date) {
            if (!date) return;
            let [year, month, day] = date.split('-');

            return `${day}/${month}/${year}`;
        },
    },
}
</script>

<style>
.msg-time {
    font-size: 11px;
    font-weight: 400;
    color: #8a8a8a;
    margin-bottom: 15px;
}

.bot-msg-time {
    padding-left: 52px;
}

.user-msg-time {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.bot-massage {
    margin-bottom: 8px;
}
</style>

<template>
    <PopupComponent class="sc-app" />
</template>

<script>
import PopupComponent from './Popup.vue'

export default {
    components: {
        PopupComponent,
    },
    data() {
        return {
            isAccessDomain: false
        }
    },
    mounted() {
        this.$axios.get(`get-access-domain`).then((response) => {
            const allowedDomain = response.data
            const currentDomain = `${window.location.host}`
            if (allowedDomain !== '' && currentDomain !== '' && currentDomain === allowedDomain) {
                this.isAccessDomain = true
            } else {
                this.isAccessDomain = false
            }
        })
    }
}
</script>
<style>
.sc-app {
    font-family: 'Noto Sans Japanese', 'Yu Gothic', YuGothic, sans-serif !important;
    font-style: normal !important;
}
</style>

<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div style="display: flex; justify-content: space-between; padding: 8px 16px; border-bottom: 1px solid #e2e2e2">
        <div style="display: flex; justify-content: start; align-items: center;">
            <img style="width: 35%" :src="getImage('logo.png')" />
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <div id="curr-flag"
                style="display: flex; justify-content: space-between; align-items: center; padding-right: 8px; position: relative; cursor: pointer; width: 46px;"
                @click="displayListLanguage = !displayListLanguage">
                <img v-if="selectedLanguage === 'ja'" :src="getImage('national/japan.png')" class="max-w-none" />
                <img v-if="selectedLanguage === 'en'" :src="getImage('national/england.png')" class="max-w-none" />
                <!-- <img v-if="selectedLanguage === 'ru'" :src="getImage('national/russia.png')" />
                <img v-if="selectedLanguage === 'cn'" :src="getImage('national/china.png')" /> -->
                <img style="padding-left: 4px" :src="getImage('flag-down.svg')" />
                <div v-show="displayListLanguage" id="chatgpt-list-languages" class="list-languages"
                    style="width: 100px; position: absolute; top: 30px; padding: 5px; border: 1px solid #e2e2e2; border-radius: 5px; left: -75px; background-color: white;">
                    <div :class="{ 'flag-active': selectedLanguage === 'ja' }" class="national-option"
                        @click="changeLocale('ja')">
                        <div class="national-name">{{ $t('Japanese') }}</div>
                        <div class="national-flag">
                            <img :src="getImage('national/japan.png')" />
                        </div>
                    </div>
                    <div :class="{ 'flag-active': selectedLanguage === 'en' }" class="national-option"
                        @click="changeLocale('en')">
                        <div class="national-name">{{ $t('English') }}</div>
                        <div class="national-flag">
                            <img :src="getImage('national/england.png')" />
                        </div>
                    </div>
                    <!-- <div class="national-option" @click="changeLocale('ru')">
                        <div class="national-name">{{ $t('Russian') }}</div>
                        <div class="national-flag">
                            <img :src="getImage('national/russia.png')" />
                        </div>
                    </div>
                    <div class="national-option" @click="changeLocale('cn')">
                        <div class="national-name">{{ $t('Chinese') }}</div>
                        <div class="national-flag">
                            <img :src="getImage('national/china.png')" />
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div style="padding-right: 8px; cursor: pointer;" @click="endChat()">
                <img :src="getImage('reload.svg')" />
            </div> -->
            <div style="cursor: pointer;" class="w-6 h-6" @click="hiddenChatWindow()">
                <img class="max-w-none" :src="getImage('close.svg')" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedLanguage: localStorage.getItem('user-locale') === null ? this.$i18n.locale : localStorage.getItem('user-locale'),
            displayListLanguage: false
        }
    },
    watch: {
        selectedLanguage(newVal) {
            this.$nextTick(() => {
                console.log("🚀 ~ selectedLanguage ~ newVal:", newVal)
            });
        },
    },
    mounted() {
        var hostElement = document.getElementById('my-shadow-dom-chat-bot');
        var shadowRoot = hostElement.shadowRoot;
        var chatgptListLanguages = shadowRoot.getElementById('chatgpt-list-languages');
        var currFlag = shadowRoot.getElementById('curr-flag');
        let self = this
        shadowRoot.addEventListener('click', function (event) {
            // Kiểm tra xem click có xảy ra bên trong myElement không
            var isClickInsidechatgptListLanguages = chatgptListLanguages.contains(event.target);
            var isClickInsidecurrFlag = currFlag.contains(event.target);

            if (!isClickInsidechatgptListLanguages && !isClickInsidecurrFlag) {
                self.displayListLanguage = false
            }
        });
    },
    methods: {
        endChat() {
            this.$eventBus.publish('end-chat')
        },
        changeLocale(locale) {
            this.selectedLanguage = locale;
            this.$i18n.locale = locale;
            localStorage.setItem('user-locale', locale)
            this.$eventBus.publish('get-main-tiem-for-locale')
        },
        hiddenChatWindow() {
            localStorage.setItem('isDownPopup', true)
            localStorage.setItem('load-full-history', false)
            this.$eventBus.publish('hidden-chat-window')
            this.$eventBus.publish('click-button-hidden-main-item')
        }
    },
}
</script>
<style>
.list-languages::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #e2e2e2;
    position: absolute;
    top: -10px;
    right: 20px;
}

.national-option {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    border-radius: 3px;
}

.national-option:hover {
    background: #e2e2e282;
}

.national-name {
    font-size: 12px;
}

.national-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.flag-active {
    color: #004d62;
    font-weight: bold;
}
</style>

<template>
    <div>
        <ChatWindow :is-open-popup="isOpenPopup" :main-item="mainItem" :bot-avatar="botAvatar" :check-disable="checkDisable"></ChatWindow>
    </div>
</template>

<script>
import ChatWindow from './ChatWindow.vue'

export default {
    components: {
        ChatWindow,
    },
    props: {
        isOpenPopup: {
            type: Boolean,
            default: false,
        },
        checkDisable: {
            type: Boolean,
            default: false,
        },
        mainItem: {
            type: Object,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    methods: {
        openAndFocus() {
            this.open()
            this.$root.$emit('focusUserInput')
        },
    },
}
</script>

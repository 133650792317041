<template>
    <div style="display: flex; justify-content: center; align-items: center; padding-bottom: 5px;">
        <div class="lds-ring-msg">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
<style>
.lds-ring-msg {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-ring-msg div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #004d63;
    border-radius: 50%;
    animation: lds-ring-msg 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #004d63 transparent transparent transparent;
}

.lds-ring-msg div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring-msg div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring-msg div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring-msg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

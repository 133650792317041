import axios from 'axios';
import Host from './host.js';
export default class AxiosService {

    constructor() {
        const {
            dataset: { botTenantId, botCode },
        } = document.currentScript
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['chat-gpt-bot-tenant-id'] = botTenantId;
        axios.defaults.headers.common['chat-gpt-bot-code'] = botCode;

        const instance = axios.create({
            baseURL: `${Host.get()}/api-chat/`,
        });
        instance.interceptors.response.use(this.handleSuccess, this.handleError);
        this.instance = instance;
    }
    objectToQueryString(obj) {
        return Object.keys(obj)
            .filter((index) => obj[index] && obj[index].toString().trim() != '')
            .map((key) => {
                return key + '=' + obj[key];
            })
            .join('&');
    }
    get(url, params = {}) {
        return this.instance.get(url + '?' + this.objectToQueryString(params));
    }
    post(url, body) {
        return this.instance.post(url, body);
    }
    put(url, body) {
        return this.instance.put(url, body);
    }
    delete(url) {
        return this.instance.delete(url);
    }
}

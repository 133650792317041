<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="bot-massage">
        <div style="display: flex">
            <div style="margin: 0 10px 0 10px">
                <img v-if="checkShow" style="height: 33px; width: 33px; border-radius: 5px; max-width: unset;"
                    :src="botAvatar" />

                <div v-else style="width: 32px;"></div>
            </div>
            <div class="gpt-text-message">
                <div class="gpt-text-message__content">
                    <div style="word-break: break-word;" v-html="message?.message?.text">
                    </div>
                    <div v-if="message.message.buttons.length > 0">
                        <!-- <div v-for="btn in message.message.buttons.filter(button => button.next_block_id !== 0)"
                            :key="btn.next_block_id" class="card-btn" @click="handleNextBlock(btn)">{{ btn?.text }}
                        </div> -->
                        <div v-for="btn in message.message.buttons" :key="btn.next_block_id" class="card-btn"
                            @click="handleNextBlock(btn)">{{ btn?.text }}
                        </div>
                    </div>
                </div>
                <!-- <div class="msg-time">{{ message?.created_at.split(" ")[1] }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
        checkShow: {
            type: Boolean,
            required: true,
        },
        botAvatar: {
            type: String,
            required: true,
        }
    },
    computed: {},
    methods: {
        handleNextBlock(btn) {
            btn.user_session = localStorage.getItem('user-session')
            this.$eventBus.publish('click-next-block-btn', btn)
        },
    },
}
</script>

<style>
.gpt-text-message {
    flex-grow: 1;
}

.gpt-text-message__content {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 12px 16px;
    width: fit-content;
    max-width: 75%;
    font-size: 13px;
    color: #373737;
    box-shadow: 0 0 5px rgb(0, 0, 0, 15%);
    border: 1px solid rgb(231 231 231 / 20%);
}

.gpt-text-message__content p {
    margin: 0;
}

.card-btn {
    word-break: break-word;
    padding: 5px;
    border: 1px solid rgb(202, 202, 202, 75%);
    border-radius: 5px;
    background-color: #ffffff;
    color: #004d62;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px #00000029;
    text-align: center;
    line-height: 1.5;
}
</style>

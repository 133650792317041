<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="gpt-rate-conversation">
        <div style="width: 90%; background-color: white; margin: 16px; border-radius: 8px">
            <div style=" padding: 10px;">
                <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
                    <span style="
                        color: #2d2d2d;
                        font-size: 24px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 28.8px;
                        word-wrap: break-word;
                    ">{{ $t('Rate') }}</span>
                    <img style="cursor: pointer" :src="getImage('close-circle.svg')" @click="hideRateChat()" />
                </div>
                <div
                    style="width: 100%; display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 16px; margin-bottom: 20px">
                    <label v-for="n in 5" :key="n" :for="'option-' + n" style="width: 100%">
                        <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 6px;
                            border: 1px solid;
                            border-color: #B8B8B8;
                            border-radius: 4px;
                            cursor: pointer;
                            height: 30px;
                        " :class="{ 'active-option': form.rate === n }">
                            <img :src="getImage('face_' + n + '.png')" />
                            <input :id="'option-' + n" v-model="form.rate" :name="'option-' + n" type="radio" :value="n"
                                style="display: none">
                        </div>
                    </label>
                </div>
                <div style="
                    width: 100%;
                    color: #282828;
                    font-size: 14px;
                    font-family: Meiryo;
                    font-weight: 700;
                    line-height: 16.8px;
                    word-wrap: break-word;
                    margin-bottom: 8px;
                ">
                    {{ $t('Do you need human support?') }}
                </div>
                <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 20px">
                    <label for="need-help-1" style="
                        margin-right: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px;
                        border: 1px solid #B8B8B8;
                        border-radius: 4px;
                        cursor: pointer;
                        width: 50%;
                        height: 30px;
                    " :class="{ 'need-help-option': form.need_help == 1 }">
                        <input id="need-help-1" v-model="form.need_help" name="need-help-1" type="radio" value="1"
                            style="display: none">
                        <span style="line-height: 1; font-size: 13px; "> {{ $t('Yes') }}</span>
                    </label>
                    <label for="need-help-2" style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px;
                        border: 1px solid #B8B8B8;
                        border-radius: 4px;
                        cursor: pointer;
                        width: 50%;
                        height: 30px;
                    " :class="{ 'need-help-option': form.need_help == 2 }">
                        <input id="need-help-2" v-model="form.need_help" name="need-help-2" type="radio" value="2"
                            style="display: none">
                        <span style="line-height: 1; font-size: 13px; "> {{ $t('No') }}</span>
                    </label>
                </div>
                <div style="width: 100%; margin-top: 16px">
                    <span style="
                        color: #282828;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 16.8px;
                        word-wrap: break-word;
                        display: block;
                    ">{{ $t('Write your review') }}<span style="color: red">*</span></span>
                    <textarea v-model="form.comment" rows="4" class="rate-text"
                        style="width: 96%; margin-top: 8px; padding: 5px; resize: none; border: 1px solid #b8b8b8; border-radius: 4px">
                </textarea>
                    <span v-if="errorRate.comment" style="font-size: 12px; color: red;">{{ $t(errorRate?.comment[0])
                        }}</span>
                </div>
                <div style="width: 100%; display: flex; justify-content: center; margin-top: 16px;">
                    <div style="
                        line-height: 1;
                        padding: 15px 24px;
                        margin-right: 8px;
                        background-color: #8a8a8a;
                        border-radius: 4px;
                        color: white;
                        font-size: 13px;
                        font-weight: 700;
                        cursor: pointer;
                    " @click="hideRateChat()">
                        {{ $t('Cancel') }}
                    </div>
                    <div style="
                        line-height: 1;
                        padding: 15px 24px;
                        background-color: #004D63;
                        border-radius: 4px;
                        color: white;
                        font-size: 13px;
                        font-weight: 700;
                        cursor: pointer;
                    " @click="submitRate()">
                        {{ $t('Send') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            form: {
                rate: '',
                need_help: '',
                comment: ''
            },
            errorRate: []
        }
    },
    watch: {
        'form.comment': function (newVal, oldVal) {
            if (this.errorRate.comment && newVal !== oldVal) {
                delete this.errorRate.comment
            }
        },
    },
    methods: {
        toogleRatePopup() {
            this.$eventBus.publish('toogle-rate-popup')
        },
        hideRateChat() {
            this.$eventBus.publish('hide-rate-popup')
            this.$eventBus.publish('disable-rate-method')
        },
        submitRate() {
            this.$eventBus.publish('toogle-loading')
            this.form.user_session = localStorage.getItem('user-session')
            this.$axios.post('customer-rate-conversation', this.form)
                .then(() => {
                    this.$eventBus.publish('hide-rate-popup')
                    this.$eventBus.publish('toogle-loading')
                    this.$eventBus.publish('thank-quote')
                })
                .catch((e) => {
                    this.errorRate = e.response.data.errors
                    this.$eventBus.publish('toogle-loading')
                });
            this.$eventBus.publish('disable-rate-method')
        }
    },
}
</script>
<style>
.gpt-rate-conversation {
    z-index: 1000000;
    background-color: #0000004d;
    position: fixed;
    right: 25px;
    bottom: 25px;
    transition: box-shadow 0.2s ease-in-out;
    width: 400px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.active-option {
    border-color: #004D63 !important;
}

.need-help-option {
    border-color: #004D63 !important;
    color: #004D63 !important;
    font-weight: bold !important;
}

.rate-text {
    font-family: Noto Sans Japanese, Yu Gothic, YuGothic, sans-serif !important;
    font-style: normal !important;
}

.rate-text:focus-visible {
    outline: none;
}

.rate-text::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
}

.rate-text::-webkit-scrollbar:horizontal {
    height: 6px;
}

.rate-text::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
}

.rate-text::-webkit-scrollbar-corner {
    background: #fff;
}

.rate-text::-webkit-scrollbar-track {
    background: #fff;
}

.rate-text::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
}

.active-rate {
    border-color: #004D63;
}
</style>

<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div class="chat-gpt-user-info">
        <div class="chat-gpt-user-info__wrap">
            <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
                <span class="chat-gpt-user-info__title">{{ $t('Please fill out the informations below ') }}</span>
            </div>
            <div style="width: 100%; margin-top: 24px">
                <span class="chat-gpt-user-info__name">{{ $t('Name') }}<span style="color: red">*</span></span>
                <input v-model="form.full_name"
                    style="width: 97%; height: 30px; border-radius: 4px; border: 1px solid #b8b8b8" type="text"
                    class="user-info-input" />
                <span v-if="errorUserInfo.full_name" style="font-size: 12px; color: red;">{{
                    $t(errorUserInfo?.full_name[0])
                }}</span>
            </div>
            <div style="width: 100%; margin: 16px 0 24px">
                <span class="chat-gpt-user-info__name">{{ $t('Email address') }}<span style="color: red">*</span></span>
                <input v-model="form.email"
                    style="width: 97%; height: 30px; border-radius: 4px; border: 1px solid #b8b8b8" type="text"
                    class="user-info-input" />
                <span v-if="errorUserInfo.email" style="font-size: 12px; color: red;">{{ $t(errorUserInfo?.email[0])
                    }}</span>
            </div>
            <div style="width: 100%; display: flex; justify-content: center">
                <div class="chat-gpt-user-info__btn" @click="startChat()">
                    {{ $t('Start chat') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                full_name: '',
                email: ''
            },
            errorUserInfo: []
        }
    },
    watch: {
        'form.full_name': function (newVal, oldVal) {
            if (this.errorUserInfo.full_name && newVal !== oldVal) {
                delete this.errorUserInfo.full_name
            }
        },
        'form.email': function (newVal, oldVal) {
            if (this.errorUserInfo.email && newVal !== oldVal) {
                delete this.errorUserInfo.email
            }
        },
    },

    methods: {
        toogleRatePopup() {
            this.$eventBus.publish('toogle-rate-popup')
        },
        startChat() {
            this.$eventBus.publish('toogle-loading')
            let params = this.form
            params.myIpv4Address = ''
            params.userSession = localStorage.getItem('user-session');
            let self = this
            fetch('https://api64.ipify.org?format=json')
                .then(response => response.json())
                .then(data => {
                    params.myIpv4Address = data.ip;
                    self.$axios.post('customer-register-information', params)
                        .then((res) => {
                            localStorage.setItem('user-session-is-set', 1);
                            this.$eventBus.publish('start-chat', { 'user_session': res.data.customer_session })
                            this.$eventBus.publish('enable-chat-input')
                            this.$eventBus.publish('toogle-loading')
                        })
                        .catch((e) => {
                            self.errorUserInfo = e.response.data.errors
                            this.$eventBus.publish('toogle-loading')
                        });
                })
                .catch(error => console.error('Đã xảy ra lỗi: ' + error));
        },
    },
}
</script>
<style>
.chat-gpt-user-info {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.chat-gpt-user-info__wrap {
    width: 80%;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.7) 0px 8px 24px;
}

.chat-gpt-user-info__title {
    color: #2d2d2d;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 28.8px;
    word-wrap: break-word;

}

.chat-gpt-user-info__name {
    color: #282828;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.8px;
    word-wrap: break-word;
    display: block;
    margin-bottom: 8px;
}

.chat-gpt-user-info__email {
    color: #282828;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.8px;
    word-wrap: break-word;
    display: block;
    margin-bottom: 8px;
}

.chat-gpt-user-info__btn {
    line-height: 1;
    padding: 15px;
    background-color: #004D63;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.user-info-input {
    padding-block: 0;
    padding-inline: 5px;
}

.user-info-input:focus-visible {
    outline: none;
}
</style>

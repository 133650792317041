<template>
    <div class="gpt-rate-conversation">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
<style>
.gpt-rate-conversation {
    z-index: 1000000;
    background-color: #0000004d;
    /* position: fixed;
    right: 25px;
    bottom: 25px;
    width: 400px;
    height: 700px; */
    position: fixed;
    right: calc(1% / 2);
    bottom: 0px;
    width: calc(100% - 2px);
    height: calc(100dvh - 1px);
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #004d63;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #004d63 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 600px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .gpt-rate-conversation {
        width: 365px !important;
        height: 550px !important;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
}
</style>

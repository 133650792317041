<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <div>
        <div class="sc-logo" style="cursor: pointer; border-radius: 50%;" @click="showChatWindow()">
            <img v-if="botAvatar != ''" :src="botAvatar" />
        </div>
        <ChatLauncher :is-open-popup="isOpenPopup" :main-item="mainItem" :bot-avatar="botAvatar"
            :check-disable="checkDisable" class="sc-app" />
        <ChatRate v-if="isOpenRatePopup" />
        <Topic v-show="isOpenTopic" ref="topicComponent" :main-item="mainItem" />
        <ChatLoading v-if="isLoading" />
        <div v-show="showDetailImg" class="chatgpt-img-view">
            <div style="position: absolute; top: 10px; right: 30px; display: flex;">
                <img style="width: 32px; cursor: pointer; margin-right: 10px;" :src="getImage('download-file.svg')"
                    @click="downloadFile" />
                <img style="width: 32px; cursor: pointer;" :src="getImage('close-circle-file.svg')"
                    @click="showDetailImg = !showDetailImg" />
            </div>
            <div style="display: flex; justify-content: center;">
                <img class="chatgpt-image" :src="imageViewUrl" />
            </div>
        </div>
    </div>
</template>

<script>
import ChatLauncher from '../Source/Launcher.vue'
import Topic from '../Source/MainItem.vue'
import ChatRate from '../Source/ChatRate.vue'
import ChatLoading from '../Source/Loading.vue'

export default {
    name: 'ChatApp',
    components: {
        ChatLauncher,
        ChatRate,
        Topic,
        ChatLoading,
    },
    data() {
        return {
            isLoading: false,
            isOpenPopup: false,
            isOpenRatePopup: false,
            isOpenTopic: false,
            showDetailImg: false,
            checkDisable: false,
            imageViewUrl: '',
            botAvatar: '',
            mainItem: []
        }
    },
    async mounted() {
        // eslint-disable-next-line no-unused-vars
        this.beforeUnloadListener = (event) => {
            localStorage.setItem('isDownPopup', true)
            localStorage.setItem('load-full-history', false)
        };
        window.addEventListener('beforeunload', this.beforeUnloadListener);
        if (localStorage.getItem('user-session') === null) {
            await this.$axios.post('guest-register-information')
                .then((res) => {
                    localStorage.setItem('user-session', res.data.customer_session);
                })
                .catch(error => console.error('Đã xảy ra lỗi khi gọi API: ' + error));
        }
        this.$eventBus.subscribe('show-rate-popup', this.handleShowRatePopup)
        this.$eventBus.subscribe('hide-rate-popup', this.handleHideRatePopup)
        this.$eventBus.subscribe('toogle-loading', this.handleToogleLoading)
        this.$eventBus.subscribe('end-chat', this.handleEndChat)
        this.$eventBus.subscribe('hidden-chat-window', this.toogleChatWindow)
        this.$eventBus.subscribe('get-main-tiem-for-locale', this.getTopics)
        this.$eventBus.subscribe('show-image', this.showImage)
        this.$eventBus.subscribe('disable-rate-method', this.disableRateMethod)
        this.$eventBus.subscribe('enable-end-chat-btn', this.enableEndChatBtn)
        this.$eventBus.subscribe('reconnect-chatgpt', this.enableForceEnd)
        this.$eventBus.subscribe('click-button-toogle-main-item', this.clickButtonToogleMain)
        this.$eventBus.subscribe('click-button-hidden-main-item', this.clickButtonHiddenMain)
        this.$eventBus.subscribe('handle-choses-main-item', this.handleChoseMain)
        this.$eventBus.subscribe('start-chat', this.checkDisableEndChatBtnStartChat)

        this.getTopics()
        this.getAvatar()
        window.Echo.connector.pusher.connection.bind('connected', function () {
            console.log('Kết nối thành công!');
        });
        window.Echo.join('user.chatroom.' + localStorage.getItem('user-session'))
            .here((users) => {
                localStorage.setItem('channel_mem', users.length)
            })
            .listen('StartMessagesEvent', (data) => {
                this.$eventBus.publish('msg-list-start-msg', data)
                this.$eventBus.publish('scroll-to-bottom-start')
            })
            .listen('SendMessageEvent', (message) => {
                this.$eventBus.publish('add-msg', message.message)
                this.$eventBus.publish('scroll-to-bottom-start')
            })
            .listen('HandleTopicMessagesEvent', (data) => {
                this.$eventBus.publish('render-topic-msg', data.data)
                this.$eventBus.publish('scroll-to-bottom-start')
            })
            .listen('ChangeAvatarEvent', (bot) => {
                this.botAvatar = bot.data?.icon_url ?? this.getImage('default-avatar-bot.svg')
            })
            .listen('TopicChangeEvent', () => {
                this.getTopics()
            })
            .listen('SendForceEndChatMessageEvent', () => {
                this.$eventBus.publish('get-force-end-chat-msg')
                this.$eventBus.publish('scroll-to-bottom-start')
            })
            .listen('BlockForceEndChatBtnEvent', () => {
                this.checkDisable = true
            })
            .listen('BlockUserChatEvent', (data) => {
                this.getTopics()
                this.$eventBus.publish('block-user-chat-event', data)
            })
            .listen('ClearHistoryChatEvent', (data) => {
                this.$eventBus.publish('clear-history-real-time', data)
            })
            .listen('SendYesNoMessageEvent', () => {
                setTimeout(() => {
                    this.$eventBus.publish('get-yes-no-msg')
                }, 3000);
                this.$eventBus.publish('scroll-to-bottom-start')
            })
            .listen('SendAdviceMessageEvent', (data) => {
                this.$eventBus.publish('msg-advice-handle-msg', data)
                this.$eventBus.publish('scroll-to-bottom-start')
            })

        let hostElement = document.getElementById('my-shadow-dom-chat-bot');
        var shadowRoot = hostElement.shadowRoot;
        var chatgptListMainitem = shadowRoot.getElementById('topic-scroll-container__w');
        var chatgptPopup = shadowRoot.getElementById('sc-chat-popup');
        let self = this
        shadowRoot.addEventListener('click', function (event) {
            var isClickInsideChatgptListMainitem = chatgptListMainitem.contains(event.target);
            var isClickInsideChatgptPopup = chatgptPopup.contains(event.target);

            if (self.isOpenTopic && !isClickInsideChatgptListMainitem && isClickInsideChatgptPopup) {
                self.isOpenTopic = false
            }
        });
    },
    unmounted() {
        this.$eventBus.unsubscribe('show-rate-popup', this.handleShowRatePopup)
        this.$eventBus.unsubscribe('hide-rate-popup', this.handleHideRatePopup)
        this.$eventBus.unsubscribe('toogle-loading', this.handleToogleLoading)
        this.$eventBus.unsubscribe('end-chat', this.handleEndChat)
        this.$eventBus.unsubscribe('hidden-chat-window', this.toogleChatWindow)
        this.$eventBus.unsubscribe('get-main-tiem-for-locale', this.getTopics)
        this.$eventBus.unsubscribe('show-image', this.showImage)
        // document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        joinChatChannel(session) {
            window.Echo.join('user.chatroom.' + session)
                .here((users) => {
                    localStorage.setItem('channel_mem', users.length)
                })
                .listen('StartMessagesEvent', (data) => {
                    this.$eventBus.publish('msg-list-start-msg', data)
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
                .listen('SendMessageEvent', (message) => {
                    this.$eventBus.publish('add-msg', message.message)
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
                .listen('HandleTopicMessagesEvent', (data) => {
                    this.$eventBus.publish('render-topic-msg', data.data)
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
                .listen('ChangeAvatarEvent', (bot) => {
                    this.botAvatar = bot.data?.icon_url ?? this.getImage('default-avatar-bot.svg')
                })
                .listen('TopicChangeEvent', () => {
                    this.getTopics()
                })
                .listen('SendForceEndChatMessageEvent', () => {
                    this.$eventBus.publish('get-force-end-chat-msg')
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
                .listen('BlockForceEndChatBtnEvent', () => {
                    this.checkDisable = true
                })
                .listen('BlockUserChatEvent', (data) => {
                    this.getTopics()
                    this.$eventBus.publish('block-user-chat-event', data)
                })
                .listen('ClearHistoryChatEvent', (data) => {
                    this.$eventBus.publish('clear-history-real-time', data)
                })
                .listen('SendYesNoMessageEvent', () => {
                    setTimeout(() => {
                        this.$eventBus.publish('get-yes-no-msg')
                    }, 3000);
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
                .listen('SendAdviceMessageEvent', (data) => {
                    this.$eventBus.publish('msg-advice-handle-msg', data)
                    this.$eventBus.publish('scroll-to-bottom-start')
                })
        },
        handleShowRatePopup() {
            this.isOpenRatePopup = true
        },
        handleHideRatePopup() {
            this.isOpenRatePopup = false
        },
        handleToogleLoading() {
            this.isLoading = !this.isLoading
        },
        handleEndChat() {
            this.isOpenRatePopup = true
            this.$eventBus.publish('hide-typing')
        },
        toogleChatWindow() {
            this.isOpenPopup = !this.isOpenPopup
        },
        showChatWindow() {
            if (localStorage.getItem('user-session-error') == 1) {
                this.$eventBus.publish('show-typing')
                this.$axios.get('get-start-msg', {
                    "user_session": localStorage.getItem('user-session'),
                    "locale": this.getLocale(this.$i18n.locale)
                })
                localStorage.setItem('user-session-error', 0)
            }
            if (localStorage.getItem('user-locale') === null) {
                localStorage.setItem('user-locale', this.$i18n.locale)
            }
            localStorage.setItem('isDownPopup', false)
            localStorage.setItem('load-full-history', false)
            this.isOpenPopup = true
            this.$i18n.locale = localStorage.getItem('user-locale')
            this.checkDisableEndChat()
            this.$eventBus.publish('check-status-end-chat')
            this.$eventBus.publish('scroll-to-bottom-start')
        },
        getTopics() {
            this.$axios.get('get-topics', {
                'user_session': localStorage.getItem('user-session'),
                'User_locale': localStorage.getItem('user-locale') ? localStorage.getItem('user-locale') : this.$i18n.locale
            })
                .then((res) => {
                    this.mainItem = res.data
                    this.$eventBus.publish('calculator-width-main-item')
                })
                .catch(async (e) => {
                    if (e.response.data.status_code === 404) {
                        await this.reCreateUser()
                        localStorage.setItem('user-session-error', 1);
                    } else {
                        this.$eventBus.publish('handle-user-is-block', e.response.data)
                        this.$eventBus.publish('handle-user-input-is-block', e.response.data)
                    }
                });
        },
        async reCreateUser() {
            await this.$axios.post('guest-register-information')
                .then((res) => {
                    window.Echo.leave('user.chatroom.' + localStorage.getItem('user-session'));
                    localStorage.setItem('user-session', res.data.customer_session);
                    this.joinChatChannel(res.data.customer_session)
                })
                .catch(error => console.error('Đã xảy ra lỗi khi gọi API: ' + error));
        },
        getLocale(l) {
            switch (l) {
                case 'ja':
                    return 1
                case 'cn':
                    return 2
                case 'ru':
                    return 3
                default:
                    return 4
            }
        },
        getAvatar() {
            this.$axios.get('get-avatar')
                .then((res) => {
                    this.botAvatar = res.data?.icon_url ?? this.getImage('default-avatar-bot.svg')
                })
                // eslint-disable-next-line no-unused-vars
                .catch((e) => {
                });
        },
        checkDisableEndChat() {
            console.log("🚀 ~ checkDisableEndChat ~ localStorage.getItem('user-session'):", localStorage.getItem('user-session'))
            this.$axios.get('check-disable-end-chat', {
                'user_session': localStorage.getItem('user-session'),
            })
                // eslint-disable-next-line no-unused-vars
                .then((res) => {
                    this.checkDisable = !res.data.enableEndChat
                })
        },
        checkDisableEndChatBtnStartChat() {
            this.checkDisable = false
        },
        disableRateMethod() {
            this.checkDisable = true
        },
        enableEndChatBtn() {
            this.checkDisable = false
        },
        enableForceEnd() {
            this.checkDisable = false
        },
        changeAvatar(data) {
            this.botAvatar = data?.icon_url ?? this.getImage('default-avatar-bot.svg')
        },
        showImage(params) {
            this.showDetailImg = true
            this.imageViewUrl = params.url
            this.imageViewName = params.file_name
        },
        async downloadFile() {
            const response = await fetch(this.imageViewUrl, {
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });
            const blob = await response.blob();

            const blobUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = blobUrl;
            anchor.download = this.imageViewName || 'default';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(blobUrl);
        },
        clickButtonToogleMain(event) {
            if (event) {
                event.stopPropagation();
            }
            if (Object.keys(this.mainItem).length == 0) {
                this.isOpenTopic = false
                return
            }
            this.isOpenTopic = !this.isOpenTopic
        },
        clickButtonHiddenMain() {
            this.isOpenTopic = false
        },
        handleChoseMain(params) {
            this.$eventBus.publish('enable-end-chat-btn')
            this.$eventBus.publish('handle-main-item-content', params)
            this.isOpenTopic = false
        },
        // handleClickOutside(event) {
        //     var hostElement = document.getElementById('my-shadow-dom-chat-bot');
        //     var shadowRoot = hostElement.shadowRoot;
        //     var chatgptListMainitem = shadowRoot.getElementById('topic-scroll-container__w');
        //     var chatgptPopup = shadowRoot.getElementById('sc-chat-popup');
        //     var isClickInsideChatgptListMainitem = chatgptListMainitem.contains(event.target);
        //     var isClickInsideChatgptPopup = chatgptPopup.contains(event.target);

        //     if (this.isOpenTopic && !isClickInsideChatgptListMainitem && isClickInsideChatgptPopup) {
        //         this.isOpenTopic = false
        //     }
        // }
    },
}
</script>

<style>
.sc-logo {
    z-index: 1000000;
    position: fixed;
    right: 25px;
    bottom: 25px;
}

.sc-logo img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
}

.chatgpt-img-view {
    z-index: 1000001;
    position: fixed;
    right: 0;
    top: 0;
    background: #0000007d;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeInScaleUp {
    from {
        opacity: 0;
        transform: scale(0.1);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.chatgpt-image {
    width: 75%;
    animation: fadeInScaleUp 400ms ease-out;
}

@media (min-width: 768px) and (max-width: 991px) {
    .sc-logo img {
        width: 50px !important;
        height: 50px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .sc-logo img {
        width: 55px !important;
        height: 55px !important;
    }
}

@media (min-width: 1200px) {
    .sc-logo img {
        width: 70px !important;
        height: 70px !important;
    }
}
</style>
